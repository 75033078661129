
import React from 'react';
import * as styles from '../../../Projects/projects.module.scss'

import Tabs from  '../Tabs/tabs';
import Process from '../../../Projects/Process/process';
import Square from '../../../Projects/Square/square';
import dev2 from '../../../../images/Search/dev2.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AnalystSection = () => {

    const {t} = useTranslation();
    
    return (
        <section className={styles.projectSection}>
            <div className={styles.containProject}>
                <div className={styles.containBody}>
                    <div className={styles.contentInfoLeft}>
                        <Tabs
                        active = 'analyst'/>

                        <Square
                            title = {t("detailsPage.analyst.square.title")}
                            subtitle= {t("detailsPage.analyst.square.subtitle")}
                            description = {t("detailsPage.analyst.square.description")}
                            list = {[t("detailsPage.analyst.square.list.0")]}                            
                            color = '#f0801f'
                            image = {dev2}
                        />      
                    </div>
                    <div className={styles.contentInfoRight}>                            
                        <Process 
                            number = '1'
                            title = {t("detailsPage.analyst.process.1.title")}
                            description = {t("detailsPage.analyst.process.1.title")}
                            list= {[t("detailsPage.analyst.process.1.list.0"),
                                    t("detailsPage.analyst.process.1.list.1"),
                                    t("detailsPage.analyst.process.1.list.2"),
                                    t("detailsPage.analyst.process.1.list.3"),
                                    t("detailsPage.analyst.process.1.list.4"),
                                    t("detailsPage.analyst.process.1.list.5")
                                ]}
                        />
                        <Process 
                            number = '2'
                            title = {t("detailsPage.analyst.process.2.title")}
                            description = {t("detailsPage.analyst.process.2.title")}
                            list= {[t("detailsPage.analyst.process.2.list.0"),
                                    t("detailsPage.analyst.process.2.list.1")
                                ]}
                        />
                    </div>
                </div>
            </div>
		</section>
    );
};
export default AnalystSection;