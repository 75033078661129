import React from "react";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import AnalystSection from "../../components/Search/Details/Analyst/analyst"
import { graphql } from "gatsby";

const AnalystPage = () => (    
    <Layout>
        <SEO title="Codeicus | Analyst" />
        <AnalystSection/>
    </Layout>
)

export default AnalystPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;